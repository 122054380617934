import { CRS } from 'leaflet';

const observer = commit => (
  new ResizeObserver(([t]) => {
    const size = t.contentBoxSize[0] || t.contentBoxSize || t.contentRect;
    commit('size', {
      width: size.inlineSize || size.width,
      height: size.blockSize || size.height,
    });
  })
);

const baseGmaps = {
  visible: false,
  lang: 'ru',
  region: 'RU',
  layerType: 'base',
};
const baseOsm = {
  visible: false,
  layerType: 'base',
  options: {
    maxNativeZoom: 19,
    maxZoom: 21,
  },
};
const withoutMap = {
  id: 0,
  type: 'osm',
  name: 'Нет карты',
  url: '',
  visible: false,
  layerType: 'base',
  options: {
    maxNativeZoom: 19,
    maxZoom: 21,
  },
};

export const state = () => ({
  resolveAddMarkers: false,
  cursorPosition: {
    lat: 0,
    lng: 0,
  },
  size: {
    width: 0,
    height: 0,
  },
  observer: null,
  center: {
    lat: +localStorage.getItem('mapCenterLat') || 0,
    lng: +localStorage.getItem('mapCenterLng') || 0,
  },
  zoom: +localStorage.getItem('mapZoom') || 0,
  crs: null,
  baseLayers: [],
  breakSetCursorPosition: false,
});

export const getters = {
  crs (state) {
    return state.crs || CRS.EPSG3857;
  },
  cursorPosition: state => state.cursorPosition,
  breakSetCursorPosition: state => state.breakSetCursorPosition,
};

export const mutations = {
  setResolveAddMarkers (state, value) {
    state.resolveAddMarkers = value;
  },
  setBaseLayers (state, layers) {
    state.baseLayers = layers.map((layer) => {
      let baseLayer = {
        id: layer.Id,
        name: layer.Name,
        url: layer.Token ? `${layer.Url}?access_token=${layer.Token}` : layer.Url,
        type: layer.Type,
      };
      if (layer.Type === 'osm') {
        baseLayer = { ...baseOsm, ...baseLayer };
      }
      if (layer.Type === 'gmaps') {
        baseLayer = { ...baseGmaps, ...baseLayer };
      }
      if (layer.ErrorTileUrl) {
        baseLayer.options.errorTileUrl = layer.ErrorTileUrl;
      }
      if (layer.TypeMap) {
        baseLayer.options = { type: layer.TypeMap };
      }
      if (layer.Subdomains) {
        baseLayer.subdomains = layer.Subdomains;
      }
      return baseLayer;
    });
    state.baseLayers.push(withoutMap);
  },
  setBreakSetCursorPosition (state, stop) {
    state.breakSetCursorPosition = stop;
  },
  setCursorPosition (state, cursorPosition) {
    state.cursorPosition = cursorPosition;
  },
  addLayer (state, layer = {}) {
    state.baseLayers.push({
      type: 'osm',
      visible: false,
      layerType: 'base',
      url: '',
      subdomains: 'abc',
      ...layer,
      crs: layer.crs ? CRS[layer.crs] : CRS.EPSG3857,
    });
  },
  size (state, size = {}) {
    state.size = { ...state.size, ...size };
  },
  observer (state, _observer = null) {
    state.observer = _observer;
  },
  setCenter (state, value) {
    // console.debug(value);
    state.center = { ...state.center, ...value };
    localStorage.setItem('mapCenterLat', value.lat);
    localStorage.setItem('mapCenterLng', value.lng);
  },
  setZoom (state, value) {
    state.zoom = value;
    localStorage.setItem('mapZoom', value);
  },
  setLayerVisible (state, id) {
    // const layers = state.baseLayers.map((_item) => {
    //   const item = _item;
    //   item.visible = item.id === id;
    //   return item;
    // });
    // state.baseLayers = layers;
    state.baseLayers.forEach((_item) => {
      const item = _item;
      item.visible = item.id === id;
      if (item.id === id) {
        state.crs = item.crs || null;
      }
    });
  },
};

export const actions = {
  async getBaseLayers ({ commit }, accountingUnitId) {
    try {
      const data = await this.$api.$get(`/v1/Maps/${accountingUnitId}`);
      commit('setBaseLayers', data);
    } catch (error) {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name}: Ошибка получения источников карт`,
        type: 'danger',
      }, { root: true });
    }
  },
  addLayer ({ commit }, layer) {
    const id = `${Date.now().toString(36)}_0`;
    commit('addLayer', {
      name: id,
      id,
      ...layer,
    });
  },
  addLayers ({ commit }, layers = []) {
    const ts = Date.now().toString(36);
    let uid = 0;
    layers.forEach((layer) => {
      const id = `${ts}_${uid += 1}`;
      commit('addLayer', {
        name: id,
        id,
        ...layer,
      });
    });
  },
  async observe ({ commit, state, dispatch }, el) {
    if (state.observer) { await dispatch('disconnect'); }

    await commit('observer', await observer(commit));

    state.observer.observe(el);
  },
  async disconnect ({ commit, state }) {
    if (state.observer) { await state.observer.disconnect(); }
    commit('observer');
  },

  setSettingsLayerMap ({ commit }, id) {
    commit('setLayerVisible', id);
  },
};
