const imgs = {};
let imagePromise;

const loadImage = url => new Promise((resolve, reject) => {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.onload = () => resolve(img);
  img.onerror = () => reject(new Error(`Failed to load image's URL: ${url}`));
  img.src = url; // создаем URL для BLOB
});

const cutTile = (tile, offset, size) => {
  const canvas = document.createElement('canvas');

  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(tile,
    offset[0], offset[1], // Start at 70/20 pixels from the left and the top of the image (crop),
    size, size, // "Get" a `50 * 50` (w * h) area from the source image (crop),
    0, 0, // Place the result at 0, 0 in the canvas,
    size, size); // With as width / height: 100 * 100 (scale)
  return canvas.toDataURL();
};

export default (obj) => {
  if (!obj) { return null; }

  const fileName = `${obj.file}_${obj.offset[0]}${obj.offset[1]}`;
  if (!imagePromise) {
    imagePromise = loadImage(obj.file);
  }

  if (!imgs[fileName] && obj.offset[0] !== null) {
    imgs[fileName] = imagePromise.then(tile => cutTile(tile, obj.offset, obj.size));
  }

  return imgs[fileName];
};
