import imageGenerator from '@/plugins/handlers/imageGenerator';

export default async function beforeRouteEnter ({ store, redirect, route }) {
  if (process.browser) { // враппер, что бы не вызывать код во время генерации
    console.log('middleware: beforeRouteEnter');
    if (route.name === 'web1c') {
      // игнорим 1с
      return;
    }

    if (route.query.apikey) {
      store.commit('user/apikey', route.query.apikey);
      if (route.name === 'locator') {
        sessionStorage.apikeyLocator = route.query.apikey;
        redirect('/locator/');
      }
    }
    if (route.name !== 'login' && route.name !== 'locator' && route.name !== 'locator-error' && store.getters['user/tokenIsNull']) {
      // если не страница логина и если нет токена, то:
      redirect('/login/');
    } else if (route.name === 'login' && !store.getters['user/tokenIsNull'] && !store.getters['user/dataIsNull']) {
      // если страница логина и есть токен и данные юзера, то:
      redirect('/monitoring/');
    } else if (route.name !== 'locator' && route.name !== 'locator-error' && !store.getters['user/tokenIsNull'] && store.getters['user/dataIsNull']) {
      // если есть токен, но нет данных юзера, то:
      if (await store.dispatch('user/fetchData')) { // запрос данных юзера по токену:
        // если успешно, то ничего не делаем
        if (route.name === 'login') { redirect('/monitoring/'); } // если логин, то на главную
        if (route.name !== 'mqtttest') {
          store.dispatch('objects/initMQTT');
          await store.dispatch('reporting/getReportTypes');
          store.dispatch('reporting/initMQTT');
          if (store.state.user.data.isAdmin && await store.dispatch('aUnits/fetchItems')) {
            console.info('Получены УЕ');
          }

          if (await store.dispatch('preferences/getPreferences')) {
            console.info('Получены настройки пользователя');
          }

          // const accountUnit = store.state.user.data.isAdmin ? store.state.user.data.AccountingUnitId : null;
          // store.commit('geofences/setAccountingUnit', accountUnit);
          const accountUnit = store.state.user.data.isAdmin ? store.getters['preferences/accountingUnitObjects'] : store.state.user.data.AccountingUnitId;

          if (await store.dispatch('objects/initObjects')) {
            const file = `${process.env.API_ADDRESS}/v1/GetIconImage(Id='common')`;
            await imageGenerator({
              file,
              size: null,
              offset: [null, null],
            });
              if (await store.dispatch('sharedobj/getSharedObjects', accountUnit) && await store.dispatch('privateobj/getPrivateObjects') && await store.dispatch('alerts/getAlertsCount')) {
                console.info('Подготовлен раздел Мониторинг');
              }
          }
        }
      } else {
        // если фейл, то затираем данные и редиректим в логин
        store.dispatch('user/logout');
        if (route.name !== 'login') {
          redirect('/login/');
        }
      }
    }

    // редирект из "/" в корневой
    if (route.name === 'index') {
      redirect('/monitoring/');
    }
  }
}
