/* eslint-disable import/prefer-default-export */
export function getObjectPropertyByPath (obj, rawPath = '', separator = '.') {
  const path = rawPath.split(separator);
  let property = obj;

  for (let i = 0, len = path.length; i < len; i += 1) {
    const key = path[i];
    property = property[key];

    if (property === undefined) {
      console.warn('before error', { rawPath, path, obj });
      throw new Error('invalid property PATH');
    }
  }
  return property;
}
