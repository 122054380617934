export const state = () => ({
  needFootprints: false,
  showObjectDirection: true,
  showObjectName: true,
  showMenu: false,
  overViewMapState: false,
  showAreaDraw: false,
  showRulerDraw: false,
  groupingObjects: true,
  layerVisible: 1,
  AUMap: false,
  geofencesSelectedIds: [],
  objectsSelectedIds: [],
  objectsVisibleIds: [],
  accountingUnitsSelected: [],
  orderReports: [],
  orderReportsMajor: [],
  orderReportsMinor: [],
  tracksSelectedColumns: '0,1,3,5,8',
  addressView: 0,
  showSettingsInReport: 1,
  useAlternativeStateColors: false,
  monitoringIconSize: 0.7,
  languageUser: 'ru',
  trackColor: '#FF0000',
  tracksColors: ['#1776b6', '#ff7f00', '#24a221', '#d8241f', '#9564bf', '#8d5649', '#e574c3', '#7f7f7f', '#bcbf00', '#00bed1', '#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d', '#666666'],
  groupingReports: false,
  groupsReports: [{ id: 1.1, view: 0, name: 'Все', open: true }, { id: 1.2, view: 1, name: 'Основные', open: true }, { id: 1.3, view: 2, name: 'Дополнительные', open: false }],
  showAccountingUnits: true,
  openFirstTrack: true,
  timeoutPopupCluster: 0.5,
  expandSearchObjects: false,
  accountingUnitGeofences: 0,
  accountingUnitObjects: 0,
  ruleSortingObjects: {},
});

export const mutations = {
  initPreferences (state, items) {
    items.forEach((item) => {
      if (state[item.Name] !== null && state[item.Name] !== undefined) {
        if (['geofencesSelectedIds', 'objectsSelectedIds', 'objectsVisibleIds', 'accountingUnitsSelected', 'groupsReports', 'orderReports', 'orderReportsMajor', 'orderReportsMinor', 'tracksColors', 'ruleSortingObjects'].includes(item.Name)) {
          state[item.Name] = JSON.parse(item.Value);
        } else if (['tracksSelectedColumns', 'languageUser', 'trackColor'].includes(item.Name)) {
          state[item.Name] = item.Value;
        } else if (['addressView', 'showSettingsInReport', 'monitoringIconSize', 'layerVisible', 'AUMap', 'accountingUnitObjects', 'accountingUnitGeofences', 'timeoutPopupCluster'].includes(item.Name)) {
          state[item.Name] = Number(item.Value);
        } else {
          state[item.Name] = item.Value === 'true';
        }
      }
    });
  },
  editPreferences (state, items) {
    Object.keys(items).forEach((value) => {
      if (state[value] !== null && state[value] !== undefined) {
        if (['geofencesSelectedIds', 'objectsSelectedIds', 'objectsVisibleIds', 'accountingUnitsSelected', 'groupsReports', 'orderReports', 'orderReportsMajor', 'orderReportsMinor', 'tracksColors', 'ruleSortingObjects'].includes(value)) {
          state[value] = JSON.parse(items[value]);
        } else {
          state[value] = items[value];
        }
      }
    });
  },
};

// TODO-ref: убрать из использования утратившие актуальность геттеры
export const getters = {
  preferences: state => state,
  // eslint-disable-next-line no-shadow
  accountingUnitGeofences: (state, getters, rootState) => (state.accountingUnitGeofences ? state.accountingUnitGeofences : rootState.user.data.AccountingUnitId),
  // eslint-disable-next-line no-shadow
  accountingUnitObjects: (state, getters, rootState) => (state.accountingUnitObjects ? state.accountingUnitObjects : rootState.user.data.AccountingUnitId),
  geofencesSelectedIds: state => state.geofencesSelectedIds || [],
  objectsSelectedIds: state => state.objectsSelectedIds || [],
  objectsVisibleIds: state => state.objectsVisibleIds || [],
  groupsReports: state => state.groupsReports,
  orderReports: state => state.orderReports || [],
  orderReportsMajor: state => state.orderReportsMajor || [],
  orderReportsMinor: state => state.orderReportsMinor || [],
  showAccountingUnits: state => state.showAccountingUnits,
  expandSearchObjects: state => state.expandSearchObjects,
  settingsAUnit: state => Object({
    addressView: state.addressView,
    showSettingsInReport: state.showSettingsInReport,
    useAlternativeStateColors: state.useAlternativeStateColors,
    monitoringIconSize: state.monitoringIconSize,
    language: state.languageUser,
    groupingReports: state.groupingReports,
    showAccountingUnits: state.showAccountingUnits,
    openFirstTrack: state.openFirstTrack,
    timeoutPopupCluster: state.timeoutPopupCluster,
    expandSearchObjects: state.expandSearchObjects,
  }),
  settingsMap: state => Object({
    showAreaDraw: state.showAreaDraw,
    showRulerDraw: state.showRulerDraw,
    overViewMapState: state.overViewMapState,
    needFootprints: state.needFootprints,
    showObjectDirection: state.showObjectDirection,
    showObjectName: state.showObjectName,
    showMenu: state.showMenu,
    layerVisible: state.layerVisible,
    AUMap: state.AUMap,
    groupingObjects: state.groupingObjects,
  }),
  trackColor: state => state.trackColor,
  tracksColors: state => state.tracksColors,
  ruleSortingObjects: state => state.ruleSortingObjects,
};

export const actions = {
  async getPreferences ({ state, commit }) {
    await this.$api.$get('/v1/Preferences').then((data) => {
      commit('initPreferences', data);
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения преференций`,
        type: 'danger',
      }, { root: true });
    });
    return true;
  },

  async setPreference ({ commit }, items) {
    // eslint-disable-next-line
    await Promise.all(Object.entries(items).map(([key, value], i) => {
      return this.$api.put(`/v1/Preferences/${key}`, JSON.stringify(value), {
        headers: {
          'content-type': 'application/json',
          // eslint-disable-next-line no-underscore-dangle
          Authorization: this.$api._defaults.headers.Authorization,
        },
      }).then(() => {
        if (i === 0) {
          commit('editPreferences', items);
        }
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка изменения преференций`,
          type: 'danger',
        }, { root: true });
      });
    }));
    return true;
  },

  setNoActivePreference ({ commit }, items) {
    commit('editPreferences', items);
  },
};
