import Vue from 'vue';
import {
  splitArray,
  JobQueue,
  liveQueue,
} from '~/plugins/handlers/utils';
import Address from '../entities/Address/Address';

const lq = liveQueue();
const splitCount = 200;

function setWork (hidden = false) {
  return global[hidden ? 'setImmediate' : 'requestAnimationFrame'];
}

function deepUpdate (source = {}, _update) {
  Object.entries(_update).forEach(([key, value]) => {
    if (value instanceof Object && source[key] instanceof Object) {
      deepUpdate(source[key], value);
    } else {
      // eslint-disable-next-line no-param-reassign
      source[key] = value;
      // Vue.set(source, key, value);
    }
  });
}

export const state = () => ({
  items: {},
  initObjects: false,
  activeObject: null,
  trackingIsActive: false,
  IsActive: false,
  error: null,
  boundsForObjects: [],
});

export const mutations = {
  setBoundsForObjects (state, value) {
    state.boundsForObjects = value;
  },
  clearItems (state) {
    state.items = {};
  },
  setActiveObject (state, id = null) {
    state.activeObject = id;
  },
  setIsActive (state, value = false) {
    state.trackingIsActive = value;
  },
  deleteItems (state, items = []) {
    items.forEach((id) => {
      Vue.delete(state.items, id);
    });
  },
  addItems (state, items = []) {
    items.forEach((el) => {
      Vue.set(state.items, el.id, el);
    });
  },
  tu (state, data) {
    const item = state.items[data.id];
    item.reactive.lat = data.lat;
    item.reactive.lng = data.lng;
    item.v += 1;
  },
  update (state, _update) {
    const item = state.items[_update.id];

    if (item) {
      deepUpdate(item, _update.data);
      if (_update.data.trackPoint) {
        if (_update.data.trackPoint.lat !== null) {
          item.reactive.lat = _update.data.trackPoint.lat;
        }
        if (_update.data.trackPoint.lng !== null) {
          item.reactive.lng = _update.data.trackPoint.lng;
        }
        if (_update.data.trackPoint.alt !== null) {
          item.reactive.alt = _update.data.trackPoint.alt;
        }
        if (_update.data.trackPoint.speed !== null) {
          item.reactive.speed = _update.data.trackPoint.speed;
        }
        if (_update.data.trackPoint.address !== undefined) {
          item.reactive.address = _update.data.trackPoint.address;
        }
        if (_update.data.trackPoint.satelliteCount !== null) {
          item.reactive.satelliteCount = _update.data.trackPoint.satelliteCount;
        }
        if (_update.data.trackPoint.lastPointTime !== null) {
          item.reactive.lastPointTime = _update.data.trackPoint.lastPointTime;
        }
        if (_update.data.trackPoint.lastValidTime !== null) {
          item.reactive.lastValidTime = _update.data.trackPoint.lastValidTime;
        }
        if (_update.data.trackPoint.color !== null) {
          item.reactive.color = _update.data.trackPoint.color;
        }
        if (_update.data.trackPoint.state !== null) {
          item.reactive.state = _update.data.trackPoint.state;
        }
        if (_update.data.trackPoint.stateBeginTime !== null) {
          item.reactive.stateBeginTime = _update.data.trackPoint.stateBeginTime;
        }
        if (_update.data.trackPoint.lastConnectionTime !== null) {
          item.reactive.lastConnectionTime = _update.data.trackPoint.lastConnectionTime;
        }
        if (_update.data.trackPoint.objectTime !== null) {
          item.reactive.objectTime = _update.data.trackPoint.objectTime;
        }
        if (_update.data.trackPoint.signal !== null) {
          item.reactive.signal = _update.data.trackPoint.signal;
        }
        if (_update.data.trackPoint.battery !== null) {
          item.reactive.battery = _update.data.trackPoint.battery;
        }
        if (_update.data.trackPoint.sensors !== null) {
          item.reactive.sensors = _update.data.trackPoint.sensors;
        }
        if (_update.data.trackPoint.lastPoints !== null) {
          item.reactive.lastPoints = _update.data.trackPoint.lastPoints;
        }
        if (_update.data.trackPoint.head !== null) {
          item.reactive.head = _update.data.trackPoint.head;
        }
      }

      item.v = item.v < 100 ? item.v + 1 : 1;
    }
  },
  updateAddress (state, _update) {
    const item = state.items[_update.id];
    if (item) {
      item.reactive.address = _update.address;
    }
  },
  clearAllAddress (state) {
    Object.keys(state.items).forEach((id) => {
      const item = state.items[id];
      item.reactive.address = null;
    });
  },
  updateTrigger (state, params) {
    const item = state.items[params.objectId];
    if (item) {
      item.data.triggers = item.data.triggers.filter(x => x.Id !== params.triggerId);
    }
  },
  // updateIcon (state, _update) { // на тот случай, если понадобиться запоминать src иконок объектов в store
  //   // console.debug(_update);
  //   const item = state.items[_update.Id];
  //   if (item) {
  //     item.data.image = _update.image;
  //   }
  // },
  visible (state, { id, visible }) {
    const item = state.items[id];
    if (item) {
      item.visible = visible;
    }
  },
  setSettingsIsActive (state, value) {
    state.IsActive = value;
  },
  setInitObjects (state) {
    state.initObjects = true;
  },
};

export const getters = {
  boundsForObjects: state => state.boundsForObjects || [],
  isError: state => state.error !== null,
  arrItems: state => Object.values(state.items) || [],
  nameActiveObject: (state, getter) => getter.arrItems.find(item => item.id === state.activeObject).name,
  imeiActiveObject: (state, getter) => getter.arrItems.find(item => item.id === state.activeObject).data.UniqueId,
  mapReadyItems: (state, getter) => getter.arrItems.filter(el => (el.visible || el.id === state.activeObject) && el.reactive.lat !== null && el.reactive.lng !== null),
  arrItemsCount: state => Object.values(state.items).length || 0,
  getReversedAddress: (state, _, rootState) => ({ id }) => {
    const item = state.items[id];
    if (!item) {
      // TODO-sentry
      console.warn('getReversedAddress: Item with this id not found!', { id });
      return '';
    }

    const { address } = item.reactive;
    // console.debug(address);
    if (address === null) {
      return '';
    }
    if (address === '') {
      return ' ';
    }

    const options = {
      // eslint-disable-next-line no-bitwise
      doReverse: +rootState.preferences.addressView ^ address.state.isReversed,
    };
    return address.prepare(options);
  },
};

export const actions = {
  runObjectToRoute ({ commit, rootState }, routeObject) {
    this.$api.$post('/v1/TrackPoints/RunObjectToRoute', routeObject).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name}: Ошибка отправления объекта по маршруту`,
        type: 'warning',
      }, { root: true });
    });
  },
  update ({ commit, rootState }, _upd) {
    lq.push((done) => {
      setWork(rootState.tabHidden)(() => {
        commit('update', _upd);
        done();
      });
    });
  },

  initMQTT ({ state, dispatch, commit, rootState }) {
    // console.debug(global.$nuxt.$mqtt.connected);
    global.$nuxt.$mqtt.on('message', (_topic, message) => {
      const topic = _topic.split('/');
      const item = {
        id: topic[3],
        messageType: topic[2],
        controller: topic[1],
      };
      const raw = JSON.parse(message.toString());

      if (item.messageType === 'State') {
        item.data = {
          trackPoint: {
            alt: raw.Altitude,
            lat: raw.Latitude,
            lng: raw.Longitude,
            speed: raw.Speed,
            satelliteCount: raw.SatelliteCount,
            lastPointTime: raw.LastMessageInsertTime ? new Date(raw.LastMessageInsertTime) : null,

            lastValidTime: raw.LastValidMessageInsertTime ? new Date(raw.LastValidMessageInsertTime) : null,
            head: raw.Heading || null,
            address: null,

            signal: raw.GsmSignal,
            battery: raw.Battery,
            sensors: raw.Sensors ? Object.keys(raw.Sensors).map((value, i) => {
              const sensor = {
                Id: i + 1,
                Name: value,
                Value: raw.Sensors[value],
              };
              return sensor;
            }) : [],
            lastPoints: raw.LastPoints || [],

            state: raw.State || null,
            color: raw.StateColor || null,
            stateBeginTime: raw.StateTimestamp ? new Date(raw.StateTimestamp) : null,
            lastConnectionTime: raw.LastMessageInsertTime ? new Date(raw.LastMessageInsertTime) : null,
            objectTime: raw.LastMessageTimestamp ? new Date(raw.LastMessageTimestamp) : null,
          },
        };

        dispatch('update', item);
      }
      if (item.controller === 'Alerts') {
        commit('notify/addNotify', {
          alertId: raw.Id,
          massage: raw.MessageText,
          type: 'success',
        }, { root: true });
        dispatch('alerts/addAlert', raw.Id, { root: true });
      }
    });
    // console.debug(global.$nuxt.$mqtt.connected);
    global.$nuxt.$mqtt.on('connect', () => {
      console.info('Установлено подключение к MQTT');
      if (global.$nuxt.$mqtt.connected && state.initObjects) {
        dispatch('initObjects', false);
      } else {
        commit('setInitObjects');
      }
    });
    // console.debug(global.$nuxt.$mqtt.connected);
    global.$nuxt.$mqtt.on('error', (error, ...other) => {
      // console.debug(global.$nuxt.$mqtt.connected);
      console.error(`Ошибка MQTT ${error.name} : ${error.message}`, { error, other });
      // commit('notify/addNotify', {
      //   massage: `Ошибка MQTT ${error.name} : ${error.message}`,
      //   type: 'danger',
      // }, { root: true });
      // console.debug(error.code);
      if (error.code === 5) {
        this.$api.$get('/v1/account').then((data) => {
          if (data) {
            global.$nuxt.$mqtt.options.clientId = `Web3_${data.SessionId}`;
            global.$nuxt.$mqtt.options.username = data.UserName;
            global.$nuxt.$mqtt.options.password = data.Secret;
            // global.$nuxt.$mqtt.end(true, {});
            global.$nuxt.$mqtt.end(true, {}, global.$nuxt.$mqtt.reconnect());
            // setTimeout(() => {
            //   global.$nuxt.$mqtt.reconnect();
            //   // console.debug(global.$nuxt.$mqtt.connected);
            // }, 100);
          }
          // console.debug(global.$nuxt.$mqtt.connected);
        }).catch((err) => {
          commit('notify/addNotify', {
            massage: `Ошибка ${err.name} : Ошибка получения данных пользователя`,
            type: 'danger',
          }, { root: true });
          // console.debug(global.$nuxt.$mqtt.connected);
        });
      }
    });
    global.$nuxt.$mqtt.stream.on('error', (error) => {
      // console.debug(global.$nuxt.$mqtt.connected);
      console.error(`Ошибка MQTT STREAM ${error.name} : ${error.message}`, error);
      // commit('notify/addNotify', {
      //   massage: `Ошибка MQTT STREAM ${error.name} : ${error.message}`,
      //   type: 'danger',
      // }, { root: true });
    });
    // console.debug(global.$nuxt.$mqtt.connected);
    global.$nuxt.$mqtt.subscribe(`/Reports/${rootState.user.data.Id}`, { rap: false });
    // console.debug(global.$nuxt.$mqtt.connected);
  },
  fetchPagedItems ({ rootState, commit }, { AccountingUnitsId = [], selected = [], UniqueId = null, Id = null, Name = null, sensors = null }) {
    let filter = '';

    if (AccountingUnitsId.length > 0) {
      filter += `AccountingUnit/Id in (${AccountingUnitsId.join(',')})`;
    }
    let sensor = '';
    if (sensors) {
      sensor = ',Sensors($select=Type,Name)';
      if (filter) {
        filter += ' and ';
      }
      sensors.forEach((x, i) => {
        if (i > 0) {
          filter += ' and ';
        }
        if (x === 'IButton' || x === 'Импульс') {
          filter += `Sensors/any(sensor: contains(sensor/Name, '${x}'))`;
        } else if (x === 'моточасы') {
          filter += `(Sensors/any(sensor: contains(sensor/Type, '${x}')) or Sensors/any(sensor: contains(sensor/Type, '${'зажигание'}')))`;
        } else {
          filter += `Sensors/any(sensor: contains(sensor/Type, '${x}'))`;
          // if (x === 'моточасы') {
          //   filter += ` or Sensors/any(sensor: contains(sensor/Type, '${'зажигание'}'))`;
          // }
        }
      });
    }
    if (Name) {
      if (filter) {
        filter += ' and ';
      }
      filter += `contains(Name, '${Name}')`;
    }
    if (Id) {
      if (filter) {
        filter += ' and ';
      }
      filter += `contains(cast(Id, 'Edm.String'), '${Id}')`;
    }
    if (UniqueId) {
      if (filter) {
        filter += ' and ';
      }
      filter += `contains(cast(UniqueId, 'Edm.String'), '${UniqueId}')`;
    }
    if (selected.length > 0) {
      if (filter) {
        filter += ' or ';
      }
      filter += `Id in (${selected.join(',')})`;
    }

    if (filter) {
      filter = `&$filter=${filter}`;
    }
    const selectObject = ['Id', 'Name', 'UniqueId', 'ObjType'].join(','); // &$select=${selectObject}
    const selectObjectAU = ['Id', 'Name'].join(',');
    if (rootState.user.token) {
      return this.$api.$get(`/v1/Objects?$expand=AccountingUnit($select=${selectObjectAU}),Icon${sensor}&$select=${selectObject}&$count=true${filter}`).then((data) => {
        if (data && data.value) {
          return data.value;
        }
        return [];
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения списка объектов`,
          type: 'danger',
        }, { root: true });
        return [];
      });
    }
    return [];
  },
  fetchItemsTrigger ({ rootState, commit }, ids = null) {
    if (ids.length > 0) {
      return this.$api.$get(`/v1/Objects?$expand=Icon&$select=Id,Name&$filter=Id in (${ids.join(',')})`).then((data) => {
        if (data && data.value) {
          return data.value;
        }
        return [];
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения объектов триггера`,
          type: 'danger',
        }, { root: true });
        return [];
      });
    }
    return [];
  },
  fetchItemsLocator ({ rootState, commit }) {
    return this.$api.$get('/v1/Objects').then((data) => {
      if (rootState.user.apikey) {
        // console.debug(data);
        if (data && data.value) {
          return data.value;
        }
      }
      return [];
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения объектов локатора`,
        type: 'danger',
      }, { root: true });
      return [];
    });
  },
  fetchAllItems ({ rootState, commit }, accountingUnit = null) {
    const selectObject = ['Id', 'Name', 'UniqueId', 'ObjType'].join(','); // &$select=${selectObject}
    const selectObjectAU = ['Id', 'Name'].join(',');
    if (rootState.user.token) {
      return this.$api.$get(`/v1/Objects?$expand=AccountingUnit($select=${selectObjectAU}),Icon&$select=${selectObject}&$count=true${accountingUnit ? `&$filter=AccountingUnitId eq ${accountingUnit}` : ''}`).then((data) => {
        if (data && data.value) {
          return data.value;
        }
        return [];
      }).catch((error) => {
        commit('notify/addNotify', {
          massage: `Ошибка ${error.name} : Ошибка получения объектов`,
          type: 'danger',
        }, { root: true });
        return [];
      });
    }
    return [];
  },

  initObjects ({ rootState, dispatch }, init = true) {
    const selectedItems = rootState.preferences.objectsSelectedIds;
    const visibleItems = rootState.preferences.objectsVisibleIds;
    if (selectedItems.length > 0) {
      return dispatch('fetchItems', { visibled: selectedItems.filter(x => visibleItems.includes(x)), init });
    }
    return false;
  },

  async selectItems ({ rootState, commit, dispatch }, { select = [], unselect = [] }) {
    let currentSelected = rootState.preferences.objectsSelectedIds.slice(0);
    const visibleItems = rootState.preferences.objectsVisibleIds.slice(0);

    select.forEach((id) => {
      currentSelected.push(id);
    });
    unselect.forEach((id) => {
      currentSelected = currentSelected.filter(x => x !== id);
    });

    await dispatch('preferences/setPreference', {
      objectsSelectedIds: JSON.stringify(Array.from(currentSelected)),
    }, { root: true });

    if (select.length > 0) {
      await dispatch('fetchItems', { visibled: select.filter(x => visibleItems.includes(x)) });
    }
    if (unselect.length > 0) {
      const queue = new JobQueue();
      splitArray(unselect, splitCount).forEach(
        chunk => queue.addJob((done) => {
          // Комитим части массива за несколько кадров
          setWork(rootState.tabHidden)(() => {
            commit('deleteItems', chunk);
            global.$nuxt.$mqtt.unsubscribe(chunk.map(id => `/Objects/State/${id}`));
            global.$nuxt.$mqtt.unsubscribe(chunk.map(id => `/Alerts/${id}`));
            done();
          });
        }),
      );
      // Запускаем очередь и ждем окончания
      await queue.start();
    }
  },

  visibleItems ({ rootState, commit, dispatch }, visible = []) {
    const currentVisible = rootState.preferences.objectsVisibleIds.slice(0);
    visible.forEach((id) => {
      currentVisible.push(id);
    });

    dispatch('preferences/setPreference', {
      objectsVisibleIds: JSON.stringify(Array.from(currentVisible)),
    }, { root: true });
    visible.forEach(id => commit('visible', { id, visible: true }));
  },

  unvisibleItems ({ rootState, commit, dispatch }, unvisible = []) {
    let currentVisible = rootState.preferences.objectsVisibleIds.slice(0);
    unvisible.forEach((id) => {
      currentVisible = currentVisible.filter(x => x !== id);
    });

    dispatch('preferences/setPreference', {
      objectsVisibleIds: JSON.stringify(Array.from(currentVisible)),
    }, { root: true });
    unvisible.forEach(id => commit('visible', { id, visible: false }));
  },

  // async getItemsSensors ({ rootState, commit }) {
  //   const selectObjectAU = ['Id', 'Name'].join(',');
  //   const selectObject = ['Id', 'Name'].join(',');
  //   const selectedItems = rootState.preferences.objectsSelectedIds;
  //   const filterObjects = selectedItems.length > 0 ? `&$filter=Id in (${selectedItems.join(',')})` : '';
  //   const data = await this.$api.$get(`/v1/Objects?$expand=AccountingUnit($select=${selectObjectAU}),Sensors($select=Type,Name)&$select=${selectObject}${filterObjects}`).catch((error) => {
  //     commit('notify/addNotify', {
  //       massage: `Ошибка ${error.name} : Ошибка получения объектов и их сенсоров`,
  //       type: 'danger',
  //     }, { root: true });
  //   });
  //   return data;
  // },

  async fetchItems ({ rootState, commit }, { visibled = [], init = false, locator = false }) {
    if (!rootState.user.token) {
      return false;
    }
    const data = await this.$api.$get(`/Frontend/Objects${locator ? '?all=true' : ''}`).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения объектов и их состояний`,
        type: 'danger',
      }, { root: true });
      return false;
    });

    if (!data) {
      return false;
    }
    commit('setBoundsForObjects', data.map((el) => {
      const stateInfo = el.State ? el.State : {};
      return [stateInfo.Latitude || null, stateInfo.Longitude || null];
    }));
    if (init) {
      commit('clearItems');
    }
    const items = data.map((item) => {
      const itemData = {
        id: item.Info.Id,
        visible: visibled.includes(item.Info.Id),
        v: 0,
      };
      const stateInfo = item.State ? item.State : {};
      const reactive = {
        lat: stateInfo.Latitude || null,
        lng: stateInfo.Longitude || null,
        alt: stateInfo.Altitude || null,
        color: stateInfo.StateColor || 'Grey',
        state: stateInfo.State || null,
        speed: stateInfo.Speed !== undefined ? stateInfo.Speed : null,
        satelliteCount: stateInfo.SatelliteCount || null,
        lastConnectionTime: new Date(stateInfo.LastMessageInsertTime * 1000),
        // objectTime: new Date(stateInfo.ObjectTime * 1000), // лишнее???
        lastValidTime: new Date(stateInfo.LastValidMessageTimestamp * 1000),
        stateBeginTime: new Date(stateInfo.StateTimestamp * 1000),
        lastPointTime: new Date(stateInfo.LastMessageTimestamp * 1000), // дубликат objectTime???
        signal: stateInfo.GsmSignal || 0,
        battery: stateInfo.Battery || null,
        sensors: stateInfo.Sensors ? Object.entries(stateInfo.Sensors).map(([key, value], i) => ({ Name: key, Value: value, Id: i + 1 })) : [],
        address: null,
        head: stateInfo.Heading || null,
        lastPoints: stateInfo.LastPoints || [],
      };
      // Отмечаем поле как "не-реактивное"
      const nonReactive = [
        ['reactive', reactive],
        ['data', item.Info],
        ['trackPoint', {}],
        ['state', {}],
      ];

      nonReactive.forEach(([name, value]) => {
        Object.defineProperty(itemData, name, {
          configurable: false,
          value,
        });
      });
      return itemData;
    });
    // console.debug(items);
    const queue = new JobQueue();
    splitArray(items, splitCount).forEach(
      chunk => queue.addJob((done) => {
        // Комитим части массива за несколько кадров
        setWork(rootState.tabHidden)(() => {
          commit('addItems', chunk);
          global.$nuxt.$mqtt.subscribe(chunk.map(item => `/Objects/State/${item.id}`), { rap: false });
          global.$nuxt.$mqtt.subscribe(chunk.map(item => `/Alerts/${item.id}`), { rap: false });
          done();
        });
      }),
    );
    // Запускаем очередь и ждем окончания
    await queue.start();
    return true;
  },

  getObjectStateInfo ({ rootState, commit }, id) {
    if (rootState.user.token) {
      return this.$api.$get(`/v1/ObjectStateInfos(${id})?$select=Latitude,Longitude,LastPointTime`).catch((e) => {
        commit('notify/addNotify', {
          massage: 'Местоположение объекта не найдено.',
          type: 'warning',
        }, { root: true });
        return false;
      });
    }
    return false;
  },

  setSettingsObjects ({ commit }, settings) {
    commit('setSettingsIsActive', settings.IsActive);
  },

  async fetchObjectsIds ({ commit }, ids = []) {
    const filter = ids.length > 0 ? `&$filter=Id in (${ids.join(',')})` : '';
    const selectObject = ['Id', 'Name', 'UniqueId', 'ObjType', 'CurrentProtocol', 'PhoneNumber', 'RideDetectorSettings($select=MinSpeed)'].join(',');
    const selectObjectAU = ['Id', 'Name'].join(',');
    const res = await this.$api.$get(`/v1/Objects?$expand=AccountingUnit($select=${selectObjectAU}),Icon,SharedGroups($select=Id,Name),Sensors($select=Type,Name)&$select=${selectObject}${filter}`).then((data) => {
      if (data && data.value) {
        return data.value;
      }
      return [];
    }).catch((error) => {
      commit('notify/addNotify', {
        massage: `Ошибка ${error.name} : Ошибка получения геозон`,
        type: 'danger',
      }, { root: true });
      return [];
    });
    return res;
  },

  async updateAddress ({ commit, dispatch }, params) {
    const { id, lat, lon, not } = params;
    const address = await dispatch('nominatim/getAddress', { lat, lon, cacheOn: false }, { root: true });

    if (!address) {
      await commit('updateAddress', { id, address: new Address(null, null, not) });
    }
    await commit('updateAddress', { id, address });
  },
};
