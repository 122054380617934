import Address from '../entities/Address/Address';

// TODO-ref - выделить cache в отдельный класс, тк повышает прозрачность кода
const cache = new Map();

function getAddressFromCache (cacheId) {
  if (!cacheId) {
    return null;
  }

  const cached = cache.get(cacheId);
  return cached || null;
}

export const state = () => ({
  prefixes: ['', 'a', 'b', 'c'],
  current: 0,
});

export const mutations = {
  increment (state) {
    state.current = state.current >= (state.prefixes.length - 1) ? 0 : state.current + 1;
  },
};

export const actions = {
  getPrefix ({ state, commit }) {
    const index = state.current;
    commit('increment');
    return state.prefixes[index];
  },

  async getAddress ({ rootState, dispatch }, { lat, lon, cacheOn = true }) {
    if (!lat || !lon) {
      return '';
    }
    let address;
    const addressIdForCash = Address.computeCacheId(lat, lon);

    address = cacheOn && getAddressFromCache(addressIdForCash);

    if (!address) {
      const prefix = await dispatch('getPrefix');
      const received = await this.$http.$get(`https://nm4${prefix}.logexpert.ru/reverse?format=json&addressdetails=0&accept-language=${rootState.preferences.languageUser}&lat=${lat}&lon=${lon}`);
      address = new Address(received.display_name);

      // Note: в кеш сохраняются только оригинальные данные
      cacheOn && cache.set(addressIdForCash, address);
    }
    const options = {
      // eslint-disable-next-line no-bitwise
      doReverse: +rootState.preferences.addressView ^ address.state.isReversed,
    };
    return address.prepare(options);
  },
};
